import React, { useState } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import useHubspotForm from "@aaronhayes/react-use-hubspot-form"
import { css } from "@emotion/core"
import { useMediaQuery, Context as ResponsiveContext } from "react-responsive"

// Components
import { Layout } from "../components/Layout"
import SEO from "../components/seo"
import { ScrollDownButton } from "../components/ScrollDownButton"
import BannerShapeTransferPricing from "../components/BannerShapeTransferPricing"

// Custom Styles & Boostrap
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Carousel from "react-bootstrap/Carousel"
import styles from "./services.module.scss"

// Content
import JSONData from "../../content/start-up.json"

const PayrollPage = ({ data }) => {
  const [index, setIndex] = useState(0)

  const isMax575px = useMediaQuery({ maxWidth: 575 })
  const isMax991px = useMediaQuery({ maxWidth: 991 })
  const isMin768px = useMediaQuery({ minWidth: 768 })
  const isMinWidth1092px = useMediaQuery({ minWidth: 1092 })

  const handleSelect = selectedIndex => {
    setIndex(selectedIndex)
  }

  const bgBtnSrc = data.slideBtnBg.publicURL

  const {
    seo_title,
    banner_title,
    banner_text,
    carousel_items,
    container_title1,
    container_text1,
    container_title2,
    container_text2,
    container_title3,
    container_text3,
    container_title4,
    container_text4,
    form_title,
    form_text,
    form_detail_title,
    form_detail_text,
    form_detail_contacts,
  } = JSONData

  useHubspotForm({
    portalId: "6202075",
    formId: "bcd65aa9-1f5f-4dd7-9606-2cc044f184f5",
    target: "#tp-hubspot-form",
  })

  return (
    <Layout>
      <SEO
        title={seo_title}
        description={banner_text}
        image={data.seoBanner.childImageSharp.resize}
      />
      <Container
        fluid
        className={styles.container}
        css={css`
          @media (min-width: 576px) {
            background-image: url(${data.bannerBackground.publicURL});
          }
        `}
      >
        <ResponsiveContext.Provider value={{ maxWidth: 575 }}>
          <BannerShapeTransferPricing />
        </ResponsiveContext.Provider>
        <Container className={styles.bannerContainer}>
          <Row className={styles.bannerRow}>
            {isMax575px ? (
              <Col>
                <h1>{banner_title}</h1>
              </Col>
            ) : (
              <>
                <Col>
                  <h1>{banner_title}</h1>
                  <p>{banner_text}</p>
                  <ScrollDownButton
                    name="Learn More"
                    sectionId="#services-slider"
                  />
                </Col>
                <Col>
                  <Img
                    fluid={data.banner.childImageSharp.fluid}
                    alt={seo_title}
                  />
                </Col>
              </>
            )}
          </Row>
        </Container>
      </Container>
     
      {/* <Container
        id="services-slider"
        fluid
        className={styles.bodyBg}
        css={css`
          @media (min-width: 576px) {
            background-image: url(${data.waveBackground.publicURL});
          }
        `}
      >
        <Container className="slide-container">
          <Carousel
            activeIndex={index}
            onSelect={handleSelect}
            controls={false}
            fade={true}
            touch={true}
            slide={false}
            className="carousel-container"
          >
            {carousel_items.map((item, i) => (
              <Carousel.Item className="slide-item" key={i}>
                <div className="img-container">
                  <Img
                    fluid={data[item.slide_img].childImageSharp.fluid}
                    className="d-block w-100"
                    alt={item.container_title}
                  />
                </div>
                <div className="desc-container">
                  <h3>{item.container_title}</h3>
                  <p>{item.container_text}</p>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
          {isMinWidth1092px && (
            <div className="card-control-container">
              {carousel_items.map((item, i) => (
                <button
                  key={i}
                  className={index === i ? "card-btn btn-bg" : "card-btn"}
                  css={
                    index === i
                      ? css`
                      background-image: url('${bgBtnSrc}');`
                      : ""
                  }
                  onClick={() => handleSelect(i)}
                >
                  {index === i ? (
                    <Img
                      fluid={data[item.btn_icon_hovered].childImageSharp.fluid}
                      className="card-btn-icon"
                      objectFit="cover"
                      objectPosition="50% 50%"
                      alt={item.container_title}
                    />
                  ) : (
                    <Img
                      fluid={data[item.btn_icon].childImageSharp.fluid}
                      className="card-btn-icon"
                      objectFit="cover"
                      objectPosition="50% 50%"
                      alt={item.container_title}
                    />
                  )}
                </button>
              ))}
            </div>
          )}
        </Container>
      </Container> */}
      <Container
        fluid
        className={styles.bodyBgNew}
        css={css`
          @media (min-width: 576px) {
            background-image: url(${data.waveBackground.publicURL});
          }
        `}
      >
        <Container>
            <Row>
                <Col><br></br><br></br><br></br>
                  <h3>{container_title1}</h3>
                  <p>{container_text1}</p>
                </Col>
                <Col>
                  <Img
                    fluid={data.service01.childImageSharp.fluid}
                    alt={banner_title}
                    className={styles.imgNew}
                    css={css`
                    float:left;
                  `}
                  />
                </Col>
            </Row>
            <br></br><br></br>
            <Row>
                <Col>
                  <Img
                    fluid={data.service02.childImageSharp.fluid}
                    alt={banner_title}
                    className={styles.imgNew}
                    css={css`
                    float:right;
                  `}
                  />
                </Col>
                <Col><br></br><br></br><br></br>
                  <h3>{container_title2}</h3>
                  <p>{container_text2}</p>
                </Col>
            </Row>
            <br></br><br></br>
            <Row>
                <Col><br></br><br></br><br></br>
                  <h3>{container_title3}</h3>
                  <p>{container_text3}</p>
                </Col>
                <Col>
                  <Img
                    fluid={data.service03.childImageSharp.fluid}
                    alt={banner_title}
                    className={styles.imgNew}
                    css={css`
                    float:left;
                  `}
                  />
                </Col>
            </Row>
            <br></br><br></br>
            <Row>
                <Col>
                  <Img
                    fluid={data.service04.childImageSharp.fluid}
                    alt={banner_title}
                    className={styles.imgNew}
                    css={css`
                    float:right;
                  `}
                  />
                </Col>
                <Col><br></br><br></br><br></br>
                  <h3>{container_title4}</h3>
                  <p>{container_text4}</p>
                </Col>
            </Row>
            
        </Container>
      </Container>
      <BackgroundImage
        Tag="section"
        fluid={data.formBgImage.childImageSharp.fluid}
        className={styles.formBg}
      >
        <Container className={styles.formSection}>
          <Container className={styles.formContainer}>
            <Row>
              <Col className={styles.formHeader}>
                <h4>{form_title}</h4>
                <p>{form_text}</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <div id="tp-hubspot-form" className="embed-responsive" />
              </Col>
              {isMin768px && (
                <Col className={styles.detailsColumn}>
                  <Img
                    fluid={data.formImage.childImageSharp.fluid}
                    className="d-block w-100"
                    alt="Automated Financial Statements"
                  />
                  <h5>{form_detail_title}</h5>
                  <p>{form_detail_text}</p>
                  <div className={styles.contactDetails}>
                    {form_detail_contacts.map((item, i) => (
                      <a
                        key={i}
                        href={item.href_link}
                        target={item.target}
                        rel={item.rel}
                      >
                        <span>
                          <Img
                            fluid={
                              data[item.contact_icon].childImageSharp.fluid
                            }
                            className={styles.contactIcon}
                            alt={item.icon_alt}
                          />
                        </span>{" "}
                        {item.contact_text}
                      </a>
                    ))}
                  </div>
                </Col>
              )}
            </Row>
          </Container>
        </Container>
      </BackgroundImage>
    </Layout>
  )
}

export default PayrollPage

export const query = graphql`
  query {
    seoBanner: file(relativePath: { eq: "banner/seo-home-banner.png" }) {
      publicURL
      childImageSharp {
        resize(width: 1024) {
          src
          height
          width
        }
      }
    }

    bannerBackground: file(relativePath: { eq: "banner/background.svg" }) {
      publicURL
    }

    banner: file(relativePath: { eq: "services/transfer-pricing.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 720) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    waveBackground: file(relativePath: { eq: "services/wave-bg.svg" }) {
      publicURL
    }

    # about transfer pricing section
    aboutTpBg: file(
      relativePath: {
        eq: "services/transfer-pricing/about-transfer-pricing-bg.png"
      }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1020) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    aboutTp: file(
      relativePath: {
        eq: "services/transfer-pricing/about-transfer-pricing.png"
      }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 720) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    # images for slides
    service01: file(
      relativePath: {
        eq: "services/transfer-pricing/01_IMAGE.png"
      }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 420) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    service02: file(
      relativePath: {
        eq: "services/transfer-pricing/02_IMAGE.png"
      }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 420) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    service03: file(
      relativePath: {
        eq: "services/transfer-pricing/03_IMAGE.png"
      }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 420) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    service04: file(
      relativePath: {
        eq: "services/transfer-pricing/04_IMAGE.png"
      }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 420) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    # slide card icons
    slideBtnBg: file(relativePath: { eq: "home/card-bg.svg" }) {
      publicURL
    }

    icon01: file(
      relativePath: {
        eq: "services/transfer-pricing/01_GREEN.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 120) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }

    icon01Hover: file(
      relativePath: {
        eq: "services/transfer-pricing/01_WHITE.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 120) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }

    icon02: file(
      relativePath: {
        eq: "services/transfer-pricing/02_GREEN.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 120) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }

    icon02Hover: file(
      relativePath: {
        eq: "services/transfer-pricing/02_WHITE.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 120) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }

    icon03: file(
      relativePath: {
        eq: "services/transfer-pricing/03_GREEN.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 120) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }

    icon03Hover: file(
      relativePath: {
        eq: "services/transfer-pricing/03_WHITE.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 120) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }

    icon04: file(
      relativePath: {
        eq: "services/transfer-pricing/04_GREEN.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 120) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }

    icon04Hover: file(
      relativePath: {
        eq: "services/transfer-pricing/04_WHITE.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 120) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }

    # Form section
    formBgImage: file(
      relativePath: { eq: "services/transfer-pricing/contact-us-bg.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    formImage: file(
      relativePath: { eq: "services/transfer-pricing/contact-us.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 420, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    addressPinIcon: file(relativePath: { eq: "address-pin.png" }) {
      childImageSharp {
        fluid(maxHeight: 85) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }

    mailIcon: file(relativePath: { eq: "mail.png" }) {
      childImageSharp {
        fluid(maxHeight: 85) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }

    mobilePhoneIcon: file(relativePath: { eq: "mobile-phone.png" }) {
      childImageSharp {
        fluid(maxHeight: 85) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }

    telephoneIcon: file(relativePath: { eq: "telephone.png" }) {
      childImageSharp {
        fluid(maxHeight: 85) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }

    viberIcon: file(relativePath: { eq: "viber.png" }) {
      childImageSharp {
        fluid(maxHeight: 85) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }

    whatsappIcon: file(relativePath: { eq: "whatsapp.png" }) {
      childImageSharp {
        fluid(maxHeight: 85) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
  }
`
